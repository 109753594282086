.techStackContainer {
  .cardList {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
    justify-content: center;
    margin: 3rem;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
      padding: 1rem;
      margin-inline: 6rem;
    }
  }

  .cardContainer {
    padding-block: 16px;

    @media (hover: hover) {
      &:hover {
        transform: scale(1.1) !important;
        transition: .2s;
      }
    }

    @media (min-width: 900px) {
      padding-inline: 16px;
    }

    .cardContent {
      display: flex;
      flex-direction: column;
      min-height: 333px;
      padding: 1.5rem;
      border-radius: 16px;
      background: linear-gradient(0deg, rgb(204 189 224) 0%, rgba(255, 255, 255, 1) 100%);
      box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
      margin: 0;
    }

    .cardHeader {
      margin-bottom: 20px;

      h2 {
        font-size: 20px;
        color: black;
      }
    }

    .cardTech {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }

    .techContainer {
      display: flex;
      align-items: center;

      .techAvatar {
        img {
          width: 40px;
          height: 40px;
          object-fit: contain;
          margin-right: 20px;
        }
      }

      .techName {
        grid-area: auto;
        color: black;
        font-weight: 500;
      }
    }
  }
}