$topbar-height: 64px;

.introBackground {
  position: absolute;
  z-index: -1;
  background: linear-gradient(0deg, rgb(183 153 223) 0%, rgba(255, 255, 255, 1) 60%);
  inset: 0;
}

.introductionContainer {
  display: grid;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  height: calc(100svh - #{$topbar-height});
  margin-bottom: 120px;

  .clipPath {
    position: absolute;
    z-index: -1;
    bottom: -100px;
    width: 100%;
    height: 100px;
    inset-inline: 0;
    clip-path: polygon(0 0, 50% 100%, 100% 0);
    background-color: rgb(183 153 223);

    @media (max-width: 425px) {
      height: 40px;
      bottom: -40px;
    }
  }

  .introSection {
    grid-column: span 4;

    @media (max-width: 768px) {
      padding-top: 0;
      grid-column: span 6;
    }
  }

  .title {
    padding-top: 10%;
    margin-bottom: 56px;

    @media (max-width: 600px) {
      font-size: 60px;
    }
  }

  .subtitle {
    font-weight: normal;

    @media (max-width: 600px) {
      font-size: 30px;
    }
  }

  .floatingImageContainer {
    display: flex;
    align-items: flex-end;
    grid-column: span 2;
    transform: scaleX(-1);

    .floatingImage {
      width: 100%;
      height: auto;
      animation: float 5s ease-in-out infinite;
    }

    @media (max-width: 768px) {
      grid-column: 4 / span 6;
    }
  }

  @keyframes float {
    0% {
      transform: translateY(0px);
    }
    50% {
      transform: translateY(-20px);
    }
    100% {
      transform: translateY(0px);
    }
  }
}
