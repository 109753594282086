* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-transform: none;
}

html {
  height: 100%;
}

body {
  background: #F5F3F5;
  min-height: 100%;
}


::-webkit-scrollbar {
  height: 10px;
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f6f6f6;
}

::-webkit-scrollbar-thumb {
  background: #999999;
}

::-webkit-scrollbar-thumb:hover {
  background: #6634AD;
}

.divider {
  margin-block: 80px !important;
  border-color: transparent !important;

  @media (max-width: 425px) {
    margin-block: 10px !important;
  }

  @media (min-width: 425px) and (max-width: 768px) {
    margin-block: 40px !important;
  }
}
