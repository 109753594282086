.servicesContainer {
  .serviceImage {
    max-width: 90%;
    margin-bottom: 24px;

    @media (min-width: 900px) {
      max-width: 80%;
      margin-bottom: 0;
    }
  }

  .content {
    display: flex;
    justify-content: center;
  }

  .row {
    position: relative;
    padding-bottom: 80px;

    &:has(.contentBackgroundLeft),
    &:has(.contentBackgroundRight) {
      padding-block: 160px;
    }

    .contentBackgroundLeft,
    .contentBackgroundRight {
      position: absolute;
      width: 100vw;
      inset-block: 0;
      margin-inline: calc(-50vw + 50%);
      max-width: calc(100vw - 5px);
      z-index: -1;
      background-color: lighten(#6634AD, 10%);
    }

    .contentBackgroundLeft {
      clip-path: polygon(0 15%, 100% 0, 100% 85%, 0 100%);
    }

    .contentBackgroundRight {
      clip-path: polygon(0 0, 100% 15%, 100% 100%, 0 85%);
    }

    @media (min-width: 900px) {
      padding-block: 60px;

      &:not(:last-of-type) {
        margin-bottom: 30px;
      }
    }
  }

  h5 {
    @media (max-width: 768px) {
      text-align: center;
    }

    @media (min-width: 768px) {
      font-size: 20px;
    }
  }

  .order-lg-2 {
    @media (min-width: 900px) {
      order: 2;
    }
  }
}
