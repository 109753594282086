.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;

  .socialMediaButton {
    img {
      width: 35px;
      height: auto;
      padding: 5px;
    }
  }

  .socialMediaLinks {
    margin-bottom: 8px;
  }
}
