.appBar {
  background-color: #F5F3F5 !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;

  .toolbar {
    justify-content: space-between;
  }

  .logo {
    display: flex;
    align-items: center;

    img {
      cursor: pointer;
      height: 60px;
      margin-right: 10px;

      @media (max-width: 768px) {
        height: 50px;
      }
    }
  }

  .menuItems {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .menuButton {
      border-radius: 999px;
      color: #F5F3F5;
      background-color: #6634AD;

      &:hover {
        background-color: #5B2A80;
      }

      @media (max-width: 425px) {
        padding: 6px;
      }
    }
  }
}






