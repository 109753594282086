.reviewContainer {
  padding-block: 2rem;
  text-align: center;
  position: relative;

  .reviewContainerWrapper {
    position: absolute;
    inset: 0;
    background-color: #EDEDED;
    z-index: -1;
    margin-inline: calc(-50vw + 50%);
    max-width: calc(100vw - 5px);
  }

  .title {
    margin-bottom: 40px;
  }

  .imgContainer {
    position: relative;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin: 0 auto 1.5rem;

    &::before {
      content: "";
      width: 100%;
      height: 100%;
      background: #6634AD;
      position: absolute;
      top: -0.25rem;
      right: -0.5rem;
      border-radius: 50%;
    }
  }

  .personImg {
    width: 100%;
    display: block;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
    position: relative;
  }

  .quoteIcon {
    position: absolute;
    top: 0;
    left: 0;
    width: 2.5rem;
    height: 2.5rem;
    display: grid;
    place-items: center;
    border-radius: 50%;
    transform: translateY(25%);
    color: #F5F3F5;
    background: #6634AD;
  }

  .author {
    margin-bottom: 0.25rem;
  }

  .job {
    margin-bottom: 0.75rem;
    text-transform: uppercase;
    font-size: 0.85rem;
  }

  .info {
    margin-bottom: 2rem;
    color: #6634AD;
    padding-inline: 100px;
    min-height: 85px;
    font-weight: bold;

    @media (max-width: 425px) {
      padding-inline: 10px;
      min-height: 235px;
    }

    @media (min-width: 425px) and (max-width: 768px) {
      padding-inline: 25px;
      min-height: 170px;
    }
  }

  .prevBtn,
  .nextBtn {
    border-color: transparent;
    margin: 0 0.5rem;
    cursor: pointer;
    width: 35px;
    height: 35px;
    border-radius: 30px;
    color: #F5F3F5;
    background-color: #6634AD;
  }

  .prevBtn:hover,
  .nextBtn:hover {
    background-color: #5B2A80;
  }
}