.teamContainer {
  img {
    width: 100%;
    height: auto;

    @media (min-width: 768px) {
      width: 75%;
    }
  }

  .leftColumn {
    display: flex;
    justify-content: center;
  }

  .midColumn {
    font-weight: 100;
    margin-block: 20px;

    @media (max-width: 425px) {
      font-size: 24px;
    }
  }

  .rightColumn {
    display: flex;
    justify-content: center;
  }

}

.preLine {
  white-space: pre-line;
}