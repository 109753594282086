.underline {
  border-bottom: 16px solid #6634AD;
  width: 100px;
  margin-bottom: 100px;

  @media (max-width: 425px) {
    border-bottom-width: 8px;
    width: 60px;
  }

  @media (max-width: 768px) {
    margin-bottom: 24px;
  }
}

.titleHeading {
  @media (max-width: 425px) {
    font-size: 28px !important;
  }
}


