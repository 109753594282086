.contactForm {
  .container {
    display: flex;
    flex-direction: column;
    padding-inline: 5%;

    @media (min-width: 768px) {
      padding-inline: 20%;
    }
  }

  .input {
    margin-bottom: 20px;
  }

  .sendMessageButton {
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 768px) {
      button {
        width: 100%;
      }
    }
  }
}